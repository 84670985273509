import React, { lazy } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import AppLayout from '../components/AppLayout'
import ProtectedRoute from './ProtectedRoute'
import AdminLayout from '../components/AdminLayout'
import AppWrapper from '../components/AppWrapper'
import AuthLayout from 'src/components/AuthLayout'

import { UserRoles } from '../state/effector/users/user.types'

import {
  DashboardPage,
  MembersPage,
  LogsPage,
  TeamPage,
  SalesPage,
  OverviewPage,
  RewardsPage,
  AirdropsPage,
  ExecuteTemplatePage,
  CreateTemplatePage,
  OneTimeAirdropPage
} from 'src/pages/AdminPanel'
import { useAccessController, useHaveAccess } from 'src/hooks/useAccessController'
import { MyAliases } from './MyAliases'

const MainPage = lazy(() => import('./MainPage'))
const MyCollection = lazy(() => import('./MyCollection'))
const PrivacyAndPolicy = lazy(() => import('./PrivacyAndPolicy'))
const NftPage = lazy(() => import('./NftPage'))
const NotFoundPage = lazy(() => import('./NotFound'))

const App = () => {
  const { isAllowed: isAllowedOnlyNotForEditor } = useAccessController(
    UserRoles.Editor,
    false,
    true
  )

  const { isAllowed: isAdmin } = useHaveAccess({
    accessRoles: [UserRoles.Admin, UserRoles.SuperAdmin]
  })

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route element={<AppLayout />}>
            {/* Application routes */}
            <Route element={<AppWrapper />}>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <MainPage />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/my-aliases"
                element={
                  <ProtectedRoute>
                    <div id="xype-root" />
                    <MyAliases />
                  </ProtectedRoute>
                }
              ></Route>

              <Route path="*" element={<NotFoundPage />}></Route>
              <Route
                path="/my-collection"
                element={
                  <ProtectedRoute>
                    <MyCollection />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/my-collection/:contractId/:tokenId"
                element={
                  <ProtectedRoute>
                    <NftPage />
                  </ProtectedRoute>
                }
              ></Route>

              <Route path="/privacy-policy" element={<PrivacyAndPolicy />}></Route>
            </Route>
          </Route>

          {/* Admin panel routes */}
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Navigate to="./dashboard" />} />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="members"
              element={
                <ProtectedRoute>
                  <MembersPage />
                </ProtectedRoute>
              }
            />
            {isAllowedOnlyNotForEditor && (
              <Route
                path="overview"
                element={
                  <ProtectedRoute>
                    <OverviewPage />
                  </ProtectedRoute>
                }
              />
            )}
            <Route
              path="logs"
              element={
                <ProtectedRoute>
                  <LogsPage />
                </ProtectedRoute>
              }
            />
            {isAllowedOnlyNotForEditor && (
              <Route
                path="sales"
                element={
                  <ProtectedRoute>
                    <SalesPage />
                  </ProtectedRoute>
                }
              />
            )}
            {isAllowedOnlyNotForEditor && (
              <Route
                path="rewards"
                element={
                  <ProtectedRoute>
                    <RewardsPage />
                  </ProtectedRoute>
                }
              />
            )}
            <Route
              path="team"
              element={
                <ProtectedRoute>
                  <TeamPage />
                </ProtectedRoute>
              }
            />
            {isAdmin && (
              <Route
                path="airdrops"
                element={
                  <ProtectedRoute>
                    <AirdropsPage />
                  </ProtectedRoute>
                }
              />
            )}
            {isAdmin && (
              <Route
                path="airdrops/template/:id?"
                element={
                  <ProtectedRoute>
                    <CreateTemplatePage />
                  </ProtectedRoute>
                }
              />
            )}

            {isAdmin && (
              <Route
                path="airdrops/execute/:id"
                element={
                  <ProtectedRoute>
                    <ExecuteTemplatePage />
                  </ProtectedRoute>
                }
              />
            )}
            {isAdmin && (
              <Route
                path="airdrops/one-time"
                element={
                  <ProtectedRoute>
                    <OneTimeAirdropPage />
                  </ProtectedRoute>
                }
              />
            )}
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App
