import React from 'react'
import { networks } from 'src/constants/currency'

export const useAvailableNftTypes = (chainId: number, maintenance?: boolean) => {
  const now = new Date()

  const nftTypes = React.useMemo(() => {
    return networks[`${chainId}` as keyof typeof networks].networkAddresses.NftTypes.filter(
      (x) => !x.discontinueDate || !x.startDate || (x.discontinueDate > now && x.startDate < now)
    )
  }, [chainId])

  return nftTypes
}
