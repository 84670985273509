import usdtIcon from '../assets/currency/USDT.svg'
import usdcIcon from '../assets/currency/USDC.svg'
import busdIcon from '../assets/currency/BUSD.svg'
import daiIcon from '../assets/currency/DAI.svg'
import { ENetworkTypes } from '../types/common.types'

export const RouterToken = '0x990A78A913deD684966EA37006Cf0A9cDd14923d'

export const networks = {
  '1': {
    networkName: 'ethereum',
    networkType: ENetworkTypes.ETH,
    networkAddresses: {
      TreasuryAddress: '0x3eB3a57c0590c99C18F2b0BcF9f91f282406fa85',
      UsAirdropSafe: '0x8988F7c151Ec1f5D1B3C4D1d59960D8B58d43Ff3',
      AccessAddress: '0xA9aE0C9ae932F637c3c746aABde77e03428f57E1',
      OperatorAddress: '0x9970c569ae98c9ecC775Dc187225d7a21F456211',
      InternationalAirdropSafe: '0xb0992Beef5a61aF6Da46b6E53e605B0DAECFEf24',
      NFTSafe: '0xACB92577C9A6f69d06fb1AeCe367575fFd9217ac',
      //   SixthSocietySaleAddress: '0x9cb178d836744437207b6ea9353e8207849e8cb0',
      //   SixthSocietyNFT: '0xf14c9B81CC4Ba8569d4FF125951c349e5f828ae0',
      NftTypes: [
        {
          fullname: 'Sixth Society - Founding Member',
          SixthSocietySaleAddress: '0x896989DD3CDEAd8190d73aDA79a1fabbA1170b96',
          SixthSocietyNFT: '0xf14c9B81CC4Ba8569d4FF125951c349e5f828ae0',
          maxCount: 10
        },
        {
          fullname: 'Sixth Society - 2023',
          SixthSocietySaleAddress: '0x896989DD3CDEAd8190d73aDA79a1fabbA1170b96',
          SixthSocietyNFT: '0x06ea25f7355CA7A1c8700EB1fC116339BBb4EFf3',
          startDate: new Date(Date.UTC(2022, 0, 1)),
          discontinueDate: new Date(Date.UTC(2023, 9, 1)),
          maxCount: 1
        },
        {
          fullname: 'Sixth Society - 2024',
          SixthSocietySaleAddress: '0x896989DD3CDEAd8190d73aDA79a1fabbA1170b96',
          SixthSocietyNFT: '0x6f00e00B16644C5C320e36d05628f8fC0cdFD433',
          startDate: new Date(Date.UTC(2023, 9, 1)),
          discontinueDate: new Date(Date.UTC(2025, 0, 1)),
          maxCount: 1
        }
      ]
    },
    currencies: {
      USDC: {
        path: ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'],
        token: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        decimals: '6',
        icon: usdcIcon,
        gasless: true
      },
      USDT: {
        path: ['0xdAC17F958D2ee523a2206206994597C13D831ec7'],
        token: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        decimals: '6',
        icon: usdtIcon,
        gasless: false
      },
      DAI: {
        path: ['0x6B175474E89094C44Da98b954EedeAC495271d0F'],
        token: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        decimals: '18',
        icon: daiIcon,
        gasless: true
      },
      BUSD: {
        path: ['0x4Fabb145d64652a948d72533023f6E7A623C7C53'],
        token: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
        decimals: '18',
        icon: busdIcon,
        gasless: false
      },
      ETH: {
        path: [
          '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
          '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
        ],
        token: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        decimals: '18',
        icon: usdtIcon,
        gasless: false
      }
    }
  },
  '11155111': {
    networkName: 'sepolia',
    networkType: ENetworkTypes.SEPOLIA,
    networkAddresses: {
      TreasuryAddress: '0x987c9600B353a1c54d6E995BEd8A4F24F85e57fF',
      UsAirdropSafe: '0x552f088a7E14b0b1B732fDD7faA9Eeac39B06318',
      AccessAddress: '0x39705002E1c60E825907a6D34e6eaf5EA3afCDCc',
      OperatorAddress: '0xe78562F729FB2dd1cD76cb28daa615a2a56A41AD',
      InternationalAirdropSafe: '0x987c9600B353a1c54d6E995BEd8A4F24F85e57fF',
      NFTSafe: '0x987c9600B353a1c54d6E995BEd8A4F24F85e57fF',
      //<- Staging SaleAddress ->
      //   SixthSocietySaleAddress: '0x3Db138B03C15234053aCdbDaE3834426346e686F',
      //<- Dev SaleAddress ->
      //   SixthSocietySaleAddress: '0x05fa934cA2CaAbB3E77B33136Fa2EAdADE26eA52',
      NftTypes: [
        {
          fullname: 'Sixth Society - Founding Member',
          SixthSocietySaleAddress: '0x3Db138B03C15234053aCdbDaE3834426346e686F',
          SixthSocietyNFT: '0xBF90ec657465c4114D6e23215A1298cc5A763664',
          maxCount: 10
        },
        {
          fullname: 'Sixth Society - 2023',
          SixthSocietySaleAddress: '0x3Db138B03C15234053aCdbDaE3834426346e686F',
          SixthSocietyNFT: '0x203Bb4C2C3a0aEf8eB8c9F70C94476531011a896',
          startDate: new Date(Date.UTC(2022, 0, 1)),
          discontinueDate: new Date(Date.UTC(2023, 9, 1)),
          maxCount: 1
        },
        {
          fullname: 'Sixth Society - 2024',
          SixthSocietySaleAddress: '0x3Db138B03C15234053aCdbDaE3834426346e686F',
          SixthSocietyNFT: '0x2832A150F2aaF82672be6f545584DcB9F9C3ec9C',
          startDate: new Date(Date.UTC(2023, 9, 1)),
          discontinueDate: new Date(Date.UTC(2025, 0, 1)),
          maxCount: 1
        }
      ]
    },
    currencies: {
      USDM: {
        path: ['0x34f2c50DBA5e998690C1b5047A74405c2FF2C54F'],
        token: '0x34f2c50DBA5e998690C1b5047A74405c2FF2C54F',
        decimals: '6',
        icon: usdtIcon,
        gasless: true
      },
      USDT: {
        path: ['0xd9a6efECb042cB0f892EA61006e0f51190526D52'],
        token: '0xd9a6efECb042cB0f892EA61006e0f51190526D52',
        decimals: '12',
        icon: usdtIcon,
        gasless: false
      },
      DAI: {
        path: ['0x502D064E69862B93c72D93c324B732E57d651678'],
        token: '0x502D064E69862B93c72D93c324B732E57d651678',
        decimals: '18',
        icon: daiIcon,
        gasless: true
      },
      ETH: {
        path: [
          '0x305E0ccd817b39C330380cd81FC9d1ace89a3471',
          '0x34f2c50DBA5e998690C1b5047A74405c2FF2C54F'
        ],
        token: '0x305E0ccd817b39C330380cd81FC9d1ace89a3471',
        decimals: '18',
        icon: usdtIcon,
        gasless: false
      }
    }
  }
}

export const Paths = {
  '1': {
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
      symbol: 'USDC'
    },
    '0xdAC17F958D2ee523a2206206994597C13D831ec7': {
      symbol: 'USDT'
    },
    '0x4Fabb145d64652a948d72533023f6E7A623C7C53': {
      symbol: 'BUSD'
    },
    '0x6B175474E89094C44Da98b954EedeAC495271d0F': {
      symbol: 'DAI'
    },
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2,0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
      symbol: 'USDC'
    },
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2,0xdAC17F958D2ee523a2206206994597C13D831ec7': {
      symbol: 'USDT'
    }
  },
  '11155111': {
    '0x34f2c50DBA5e998690C1b5047A74405c2FF2C54F': {
      symbol: 'USDM'
    },
    '0x502D064E69862B93c72D93c324B732E57d651678': {
      symbol: 'DAI'
    },
    '0xd9a6efECb042cB0f892EA61006e0f51190526D52': {
      symbol: 'USDT'
    },
    '0x305E0ccd817b39C330380cd81FC9d1ace89a3471,0x34f2c50DBA5e998690C1b5047A74405c2FF2C54F': {
      symbol: 'USDM'
    },
    '0x305E0ccd817b39C330380cd81FC9d1ace89a3471,0xd9a6efECb042cB0f892EA61006e0f51190526D52': {
      symbol: 'USDT'
    }
  }
} as Record<string, Record<string, { symbol: string }>>
