import React from 'react'
import { useStore } from 'effector-react'
import { css, TableContainer, Tooltip } from '@mui/material'
import moment from 'moment/moment'

import { $sellTransactions } from '../../../state/effector/token/token.store'
import {
  CellLightRow,
  renderCellWithTwoRows,
  Table,
  HeaderRow,
  BodyRow,
  CellPrimary
} from '../Table'
import { ISalesTransaction } from '../../../state/effector/token/token.types'
import { AddressesCell } from '../Table/AddressesCell'
import { ENetworkTypes } from 'src/types/common.types'
import saleImg from '../../../assets/sale.png'
import upgradeImg from '../../../assets/upgrade.png'
import { Paths } from '../../../constants/currency'
import useMetaMask from '../../../hooks/useMetaMask'
import { getNftName } from 'src/utils/nft.utils'
import { Totals } from './totals'

const headerData: string[] = [
  'Type',
  'Date of sale',
  'Buyer',
  'Purchase',
  'My Triber',
  'Commission',
  'Airdrop deposit',
  'Profit',
  'Execution'
]

const transactionTypes = {
  DApp: 'DApp',
  SendViaPanel: 'Panel',
  Manual: 'Manual'
} as Record<string, string>

export const SalesTable = () => {
  const { chainId } = useMetaMask()
  const paths = Paths[chainId]
  const sellTransactions = useStore($sellTransactions)

  const getFormattedLink = (network: ENetworkTypes, txHash: string) =>
    (network === ENetworkTypes.SEPOLIA && `https://sepolia.etherscan.io/tx/${txHash}`) ||
    (network === ENetworkTypes.ETH && `https://etherscan.io/tx/${txHash}`) ||
    '#'

  return (
    <TableContainer sx={{ overflow: 'none' }}>
      <Totals />
      <Table aria-label="customized table" sx={{ minWidth: '1220px' }}>
        <HeaderRow rowTemplate={rowTemplate}>
          {headerData.map((header) => (
            <CellPrimary key={header}>{header}</CellPrimary>
          ))}
        </HeaderRow>
        <>
          {sellTransactions?.items
            ? sellTransactions?.items.map((row: ISalesTransaction, idx) => (
                <BodyRow key={row.id} rowTemplate={rowTemplate}>
                  <CellPrimary>
                    <Tooltip title={row.type} placement="top" arrow>
                      <img
                        src={row.type === 'Sale' ? saleImg : upgradeImg}
                        alt="typeImg"
                        width="24px"
                        height="24px"
                      />
                    </Tooltip>
                  </CellPrimary>
                  <CellPrimary>
                    <Tooltip
                      title={
                        <>
                          {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                          <br />
                          {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                        </>
                      }
                      placement="top"
                      arrow
                    >
                      <a
                        href={getFormattedLink(row.network, row.txHash)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')} (GMT)
                      </a>
                    </Tooltip>
                  </CellPrimary>
                  <CellPrimary>
                    <AddressesCell
                      address={row.user.ethAddress || '-'}
                      name={row.user.name}
                      id={`${idx}_buyer`}
                      isShowUsIcon={row.user.country === 'US'}
                    />
                  </CellPrimary>
                  <CellPrimary>
                    {renderCellWithTwoRows(
                      <CellLightRow>
                        {row.amount} {getNftName(chainId, row.nftAddress)}
                      </CellLightRow>,
                      <CellLightRow>
                        {row.paymentCurrencyPath === 'Fiat' && '$'}
                        {Number(row.investment).toLocaleString('en-US')}{' '}
                        {row.paymentCurrencyPath !== 'Fiat' &&
                          ` ${
                            paths[row?.paymentCurrencyPath]?.symbol ||
                            paths[row?.commissionCurrencyPath]?.symbol ||
                            ''
                          }`}
                      </CellLightRow>
                    )}
                  </CellPrimary>

                  <CellPrimary>
                    {row.buddyModel ? (
                      <AddressesCell
                        address={row.buddyModel.ethAddress}
                        name={row.buddyModel.name}
                        id={`${idx}_triber`}
                        isShowUsIcon={row.buddyModel.country === 'US'}
                      />
                    ) : (
                      'No Buddy'
                    )}
                  </CellPrimary>
                  <CellPrimary>
                    {row.txHash &&
                    row.commissionHash &&
                    getFormattedLink(row.network, row.txHash) !==
                      getFormattedLink(row.network, row.commissionHash) ? (
                      <a
                        href={getFormattedLink(row.network, row.commissionHash)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.commissionCurrencyPath === 'Fiat' && '$'}
                        {(+row.commission || 0).toLocaleString('en-US')}
                        {row.commissionCurrencyPath !== 'Fiat' &&
                          ` ${paths[row?.commissionCurrencyPath]?.symbol || 'USDC'}`}
                      </a>
                    ) : (
                      <>
                        {row.commissionCurrencyPath === 'Fiat' && '$'}
                        {(+row.commission || 0).toLocaleString('en-US')}
                        {row.commissionCurrencyPath !== 'Fiat' &&
                          ` ${paths[row?.commissionCurrencyPath]?.symbol || 'USDC'}`}
                      </>
                    )}
                  </CellPrimary>
                  <CellPrimary>
                    {row.paymentCurrencyPath === 'Fiat' && '$'}
                    {(+(row.airdropDeposit || 0)).toLocaleString('en-US')}
                    {row.paymentCurrencyPath !== 'Fiat' &&
                      ` ${
                        paths[row?.paymentCurrencyPath]?.symbol ||
                        paths[row?.commissionCurrencyPath]?.symbol ||
                        ''
                      }`}
                  </CellPrimary>
                  <CellPrimary>
                    {row.paymentCurrencyPath === 'Fiat' && '$'}
                    {(+row.treasuryPayments || 0).toLocaleString('en-US')}
                    {row.paymentCurrencyPath !== 'Fiat' &&
                      ` ${
                        paths[row?.paymentCurrencyPath]?.symbol ||
                        paths[row?.commissionCurrencyPath]?.symbol ||
                        ''
                      }`}
                  </CellPrimary>
                  <CellPrimary>{transactionTypes[row.transactionType]}</CellPrimary>
                </BodyRow>
              ))
            : ''}
        </>
      </Table>
    </TableContainer>
  )
}

const rowTemplate = css`
  grid-template-columns:
    72px minmax(120px, 1fr) minmax(170px, 1fr) minmax(120px, 1fr) minmax(170px, 1fr)
    repeat(4, minmax(120px, 150px));

  a {
    text-decoration: underline;
  }
`
