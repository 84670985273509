import React from 'react'
import './index.css'
// import './assets/scss/variables.scss';
import './fonts/SpaceGrotesk-Bold.ttf'
import './fonts/SpaceGrotesk-Light.ttf'
import './fonts/SpaceGrotesk-Medium.ttf'
import './fonts/SpaceGrotesk-Regular.ttf'
import './fonts/SpaceGrotesk-SemiBold.ttf'
import { ThemeProvider } from '@emotion/react'
import { createRoot } from 'react-dom/client'
import Web3 from 'web3'
import App from './pages/App'
import store from './state'
import { Web3ReactProvider } from '@web3-react/core'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import MuiTheme from './theme'
import { CssBaseline } from '@mui/material'
import { MetaMaskProvider } from './hooks/useMetaMask'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import * as Sentry from '@sentry/react'
import ErrorBoundary from 'src/components/error-boundary'

if (window.location.hostname === 'app.sixthsociety.com') {
  Sentry.init({
    dsn: 'https://e9a03da0120e48eba54684a8f85f7ec7@o4504457678094336.ingest.sentry.io/4504457831317504',
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay({ maskAllText: false, blockAllMedia: false })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)

const getLibrary = (provider: unknown | any, _connector: any) => new Web3(provider)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={MuiTheme}>
      <CssBaseline enableColorScheme />
      <Web3ReactProvider getLibrary={getLibrary}>
        <MetaMaskProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
          <ToastContainer
            icon={({ type }) => (type === 'success' ? <DoneIcon /> : <ErrorOutlineIcon />)}
            theme="colored"
            toastStyle={{ fontWeight: 600 }}
            closeButton={false}
            hideProgressBar
          />
        </MetaMaskProvider>
      </Web3ReactProvider>
    </ThemeProvider>
  </Provider>
)
