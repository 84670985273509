import { Network, Alchemy, NftNamespace, NftTokenType, NftOrdering, OwnedNft } from 'alchemy-sdk'

import { ALCHEMY_API_KEY } from 'src/config'
import { TTokenType } from '../types/common.types'
import { saveIsCollectionLoading, setOtherNftList, setOurNftList } from 'src/state/effector/store'
import { nftAddresses } from 'src/constants/nftAddresses'

class AlchemyService {
  chainId: number

  alchemy!: {
    nft: NftNamespace
  }

  constructor(chainId: number) {
    this.chainId = chainId
  }

  public getAlchemy() {
    let network

    if (this.chainId === 11155111) {
      network = Network.ETH_SEPOLIA
    } else if (this.chainId === 1) {
      network = Network.ETH_MAINNET
    }

    const settings = {
      apiKey: ALCHEMY_API_KEY,
      network: network
    }

    this.alchemy = new Alchemy(settings)
  }

  async getNftCollection(walletAddress: string, pageKey: string) {
    try {
      this.getAlchemy()
      saveIsCollectionLoading(true)
      let page = pageKey

      const ourNftForUpdateCache = await this.alchemy.nft.getNftsForOwner(walletAddress, {
        orderBy: NftOrdering.TRANSFERTIME,
        pageSize: 100,
        contractAddresses: nftAddresses,
        ...(page && { pageKey: page })
      })

      const tokensForRefresh = [] as any

      ourNftForUpdateCache.ownedNfts.forEach((nft) => {
        if (nft.rawMetadata?.expiration === 'never') {
          return
        }

        tokensForRefresh.push({
          contractAddress: nft.contract.address,
          tokenId: nft.tokenId,
          tokenType: nft.tokenType
        })
      })
      if (tokensForRefresh.length) {
        await this.alchemy.nft.getNftMetadataBatch(tokensForRefresh, { refreshCache: true })
      }

      let collection = {} as Record<string, any>
      // ownedNfts

      const res = await this.alchemy.nft.getNftsForOwner(walletAddress, {
        orderBy: NftOrdering.TRANSFERTIME,
        pageSize: 100,
        ...(page && { pageKey: page })
      })
      collection = { ...res }
      page = res.pageKey || ''
      if (res.totalCount > 100) {
        for (let i = 0; i < Math.ceil((res.totalCount - 100) / 100); i++) {
          const resOther = await this.alchemy.nft.getNftsForOwner(walletAddress, {
            orderBy: NftOrdering.TRANSFERTIME,
            pageSize: 100,
            ...(page && { pageKey: page })
          })
          collection = {
            ...collection,
            ...resOther,
            ownedNfts: [...collection.ownedNfts, ...resOther.ownedNfts]
          }
          page = resOther.pageKey || ''
        }
      }

      const nftAddressesObj = nftAddresses.reduce(
        (acc, address) => ({ ...acc, [address.toLowerCase()]: true }),
        {}
      ) as Record<string, boolean>

      const { sixthSocietyCollection, otherCollection } = collection.ownedNfts.reduce(
        (
          acc: {
            sixthSocietyCollection: OwnedNft[]
            otherCollection: OwnedNft[]
          },
          nft: OwnedNft
        ) => {
          const contractAddress = nft.contract.address.toLowerCase()
          const isOur = nftAddressesObj[contractAddress]

          if (!isOur) {
            return { ...acc, otherCollection: [...acc.otherCollection, nft] }
          }
          const expiration = nft?.rawMetadata?.expiration

          return {
            ...acc,
            sixthSocietyCollection: [
              ...acc.sixthSocietyCollection,
              {
                ...nft,
                rawMetadata: {
                  ...nft.rawMetadata,
                  expiration: expiration ? +expiration * 1000 : 'never'
                }
              }
            ]
          }
        },
        { sixthSocietyCollection: [], otherCollection: [] }
      )

      setOurNftList(sixthSocietyCollection)
      setOtherNftList(otherCollection)

      return collection
    } catch (e) {
      console.error(e)
      return {
        ownedNfts: [],
        pageKey: ''
      }
    } finally {
      saveIsCollectionLoading(false)
    }
  }

  async refreshNftMetadata(contract: string, tokenId: string | number) {
    this.getAlchemy()
    return this.alchemy.nft.refreshNftMetadata(contract, tokenId)
  }

  async getNftData(contract: string, tokenId: string, tokenType?: TTokenType) {
    this.getAlchemy()
    return this.alchemy.nft.getNftMetadata(contract, tokenId, {
      tokenType: tokenType === 'ERC1155' ? NftTokenType.ERC1155 : NftTokenType.ERC721,
      refreshCache: true
    })
  }

  async verifyNftOwnership(account: string, contract: string) {
    this.getAlchemy()
    return this.alchemy.nft.verifyNftOwnership(account, contract)
  }
}

export default AlchemyService
