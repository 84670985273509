import React, { FC, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'

import { ISendNFTModal, IValues, SendNFTSuccessResponse } from './helpers/types'
import useMetaMask from '../../../hooks/useMetaMask'
import { networks } from '../../../constants/currency'
import { validationSchema } from './helpers/validation'
import { Box } from '@mui/material'
import { ENetworkTypes } from '../../../types/common.types'
import CustomModal from '../../Modal/customModal'
import apiService from '../../../services/api'
import MemberModal, { getErrorMessage } from '../Members/MemberModal/MemberModal'
import { ErrorNotRegistered, ErrorNotWhitelisted, ErrorNoIntroducer } from './ErrorComponents'
import { toast } from 'react-toastify'
import successImg from '../../../assets/modal/success_new.svg'
import isEmpty from 'lodash/isEmpty'
import { SendSuccessComponent } from './SendSuccessComponent'
import { isReceiverIsTreasuryAddressError } from './helpers/constants'
import { getAvailableResource, serializedValues } from './helpers/utils'
import { TransferNFTForm } from './TransferNFTForm'
import { SentNFTConfirmModal } from './SentNFTConfirmModal'
import { useAvailableNftTypes } from 'src/hooks/useAvailableNftTypes'

const SendNFTModal: FC<ISendNFTModal> = ({ isOpen, setIsOpen, defaultWallet, resources }) => {
  const { chainId } = useMetaMask()

  const nftTypes = useAvailableNftTypes(chainId, true)
  const networkType = networks[chainId as keyof typeof networks]?.networkType

  const [sendError, setSendError] = useState<Array<string>>([])
  const [sendSuccess, setSendSuccess] = useState<SendNFTSuccessResponse>({})
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [registerModalOpen, setRegisterModalOpen] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [isConfirmModalLoading, setIsConfirmModalLoading] = useState(false)
  const [successViewOpen, setSuccessViewOpen] = useState(false)
  const [isReceiverIsTreasuryAddress, setIsReceiverTreasuryAddress] = useState<boolean>(false)

  const onSendSubmitOpenConfirmModal = async () => {
    setIsModalLoading(true)
    setIsOpenConfirmModal(true)
    return
  }

  useEffect(() => {
    if (Object.keys(resources).length === 0) {
      setIsModalLoading(true)
    } else {
      setIsModalLoading(false)
    }
  }, [resources])

  const onSendSubmit = async () => {
    setIsConfirmModalLoading(true)
    setIsOpenConfirmModal(false)

    await apiService
      .adminSendNft(serializedValues(values, networkType))
      .then(({ data }) => {
        const message = data.message as SendNFTSuccessResponse
        setSendSuccess({ ...message, submitted: true })
        setSuccessViewOpen(true)
        setIsConfirmModalLoading(false)
      })
      .catch((response) => {
        const errorText = getErrorMessage(response)
        setSendError(errorText.includes('[') ? JSON.parse(errorText) : [errorText])
      })
      .finally(() => {
        setIsModalLoading(false)
        setIsConfirmModalLoading(false)
      })
  }

  const initialValues = useMemo(
    () =>
      ({
        nftType: 0,
        nftAddress: nftTypes[0].SixthSocietyNFT,
        amount: getAvailableResource(0, resources) || 0 > 0 ? 1 : 0,
        ethAddress: defaultWallet || '',
        networkType
      } as IValues),
    [defaultWallet, networkType, resources, nftTypes]
  )

  const sendErrorComponent = useMemo(() => {
    return sendError.map((error) => {
      setIsReceiverTreasuryAddress(isReceiverIsTreasuryAddressError === error)
      if (isReceiverIsTreasuryAddressError === error) {
        setIsReceiverTreasuryAddress(isReceiverIsTreasuryAddressError === error)
        return
      }

      switch (error) {
        case 'This wallet is not registered yet.':
          return (
            <ErrorNotRegistered message={error} handleRegister={() => setRegisterModalOpen(true)} />
          )
        case 'This wallet is not whitelisted yet.':
          return (
            <ErrorNotWhitelisted message={error} handleWhitelist={() => setEditModalOpen(true)} />
          )
        case 'This wallet doesnt have Introducer.':
          return (
            <ErrorNoIntroducer message={error} handleWhitelist={() => setEditModalOpen(true)} />
          )
        default:
          toast.error(error)
          return <></>
      }
    })
  }, [sendError])

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    handleBlur,
    setFieldValue,
    isValid,
    resetForm,
    validateForm
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSendSubmitOpenConfirmModal
  })

  const onEditMemberModalClose = async () => {
    await requestValidate(values, networkType)
    if (editModalOpen) setEditModalOpen(false)
    if (registerModalOpen) setRegisterModalOpen(false)

    // setSendError([])
  }

  const handleCloseSentNFTConfirmModal = () => {
    setIsModalLoading(false)
    setIsOpenConfirmModal(false)
    setIsConfirmModalLoading(false)
  }

  const requestValidate = async (currentValues: IValues, currentNetworkType: ENetworkTypes) => {
    try {
      const res = await apiService.adminValidateSendNft(
        serializedValues(currentValues, currentNetworkType)
      )

      const message = res.data as SendNFTSuccessResponse

      setSendSuccess(message)
      setSendError([])
    } catch (response: any) {
      const errorText = getErrorMessage(response)
      setSendError(errorText.includes('[') ? JSON.parse(errorText) : [errorText])
    } finally {
      setIsModalLoading(false)
    }
  }

  const onAmountBlur = (value: string) => {
    if (Number(value) < 1) {
      setFieldValue('amount', 1)
    }
  }

  useEffect(() => {
    setSendError([])
    setSendSuccess({})
    if (isValid && JSON.stringify(values) !== JSON.stringify(initialValues)) {
      validateForm(values).then((errorsTemp) => {
        if (isEmpty(errorsTemp)) {
          setIsModalLoading(true)
          requestValidate(values, networkType)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values), isValid, JSON.stringify(initialValues)])

  useEffect(() => {
    if (!registerModalOpen && !editModalOpen)
      validateForm(values).then((errorsTemp) => {
        if (isEmpty(errorsTemp)) {
          setIsModalLoading(true)
          requestValidate(values, networkType)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModalOpen, registerModalOpen])

  useEffect(() => {
    validateForm(values).then((errorsTemp) => {
      if (isEmpty(errorsTemp) && defaultWallet) {
        setIsModalLoading(true)
        requestValidate(values, networkType)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultWallet, JSON.stringify(values)])

  const handleClose = () => {
    setIsOpen(false)
    setSuccessViewOpen(false)
    setSendSuccess({})
    setSendError([])
    resetForm()
  }

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={successViewOpen ? 'NFT has been sent successfully!' : 'Send NFT'}
      isLoading={isModalLoading}
    >
      {successViewOpen ? (
        <>
          <Box display="flex" justifyContent="center" pb={4} pt={2}>
            <img src={successImg} alt="" />
          </Box>
          {isEmpty(sendError) && (
            <SendSuccessComponent sendSuccess={sendSuccess} values={values} chainId={chainId} />
          )}
        </>
      ) : (
        <TransferNFTForm
          chainId={chainId}
          values={values}
          isModalLoading={isModalLoading}
          sendSuccess={sendSuccess}
          resources={resources}
          sendError={sendError}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          isReceiverIsTreasuryAddress={isReceiverIsTreasuryAddress}
          isValid={isValid}
          onSendSubmit={onSendSubmitOpenConfirmModal}
          onAmountBlur={onAmountBlur}
          sendErrorComponent={sendErrorComponent}
          submitBtnLabel="Send"
          setFieldValue={setFieldValue}
        />
      )}

      {Boolean(editModalOpen) && Boolean(values.ethAddress) && (
        <MemberModal
          type="edit"
          ethAddress={values.ethAddress}
          withoutButtons={true}
          onClose={onEditMemberModalClose}
        />
      )}
      {Boolean(registerModalOpen) && Boolean(values.ethAddress) && (
        <MemberModal
          type="add"
          ethAddress={values.ethAddress}
          withoutButtons={true}
          onClose={onEditMemberModalClose}
        />
      )}
      <SentNFTConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={onSendSubmit}
        handleClose={handleCloseSentNFTConfirmModal}
        ethAddress={values.ethAddress}
        amount={values.amount}
        isLoading={isConfirmModalLoading}
        nftAddress={values.nftAddress}
      />
    </CustomModal>
  )
}

export default SendNFTModal
