import React from 'react'
import styled from '@emotion/styled'
import { InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'

import SearchBar from '../../SearchBar'
import { colors } from '../../../utils/colorVariables'
import RadioButton from '../../RadioButton'
import { ButtonTransparent } from '../../Button'
import { MEDIA_WIDTHS } from '../../../constants'

import useMetaMask from 'src/hooks/useMetaMask'
import { useAvailableNftTypes } from 'src/hooks/useAvailableNftTypes'
import { getNftName } from 'src/utils/nft.utils'

interface Props {
  handleFilters: any
  filters: Record<string, any>
}

export const Filters = ({ filters, handleFilters }: Props) => {
  const { chainId } = useMetaMask()
  const nftTypes = useAvailableNftTypes(chainId, true)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value
    const searchWithTrim = search.trim()
    const inputName = event.target.name
    handleFilters((state: any) => ({ ...state, [inputName]: searchWithTrim }))
  }

  const onClearFilters = () => {
    handleFilters({
      walletAddress: '',
      introducerWalletAddress: '',
      nftAddress: 'all',
      transactionType: 'all',
      type: 'all',
      fromDate: '',
      toDate: ''
    })
  }

  const onDateChange = (name: string, value: string) => {
    handleFilters((state: any) => ({ ...state, [name]: value }))
  }

  return (
    <Container>
      <SearchBar
        name="walletAddress"
        value={filters.walletAddress}
        onChange={handleSearchChange}
        placeholder="Buyer wallet/name"
      />
      <SearchBar
        name="introducerWalletAddress"
        value={filters.introducerWalletAddress}
        onChange={handleSearchChange}
        placeholder="Introducer wallet"
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          label="From"
          value={filters.fromDate ? moment(filters.fromDate).utc() : null}
          sx={{
            '& > *': { height: '50px', cursor: 'pointer !important' },
            '& input': { fontSize: '16px', cursor: 'pointer' }
          }}
          maxDate={filters.toDate ? moment(filters.toDate) : moment()}
          onChange={(value) => {
            const newDate = value?.add(value?.utcOffset(), 'minutes')?.toISOString()
            onDateChange('fromDate', newDate || '')
          }}
          disableFuture
          className="date-picker"
          closeOnSelect
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          value={filters.toDate ? moment(filters.toDate).utc() : null}
          label="To"
          sx={{
            '& > *': { height: '50px', cursor: 'pointer !important' },
            '& input': { fontSize: '16px', cursor: 'pointer' }
          }}
          minDate={filters.fromDate ? moment(filters.fromDate) : undefined}
          maxDate={moment()}
          onChange={(value) => {
            const newDate = value?.add(value?.utcOffset(), 'minutes')?.toISOString()
            onDateChange('toDate', newDate || '')
          }}
          className="date-picker"
          disableFuture
          closeOnSelect
        />
      </LocalizationProvider>
      <SelectFilter
        name="nftAddress"
        value={filters.nftAddress}
        onChange={handleFilters}
        options={[
          { label: 'All NFTs', value: 'all' },

          ...nftTypes.map((x) => ({
            label: getNftName(chainId, x.SixthSocietyNFT),
            value: x.SixthSocietyNFT
          }))
        ]}
      />
      <SelectFilter
        name="transactionType"
        value={filters.transactionType}
        onChange={handleFilters}
        options={[
          { label: 'All Executions', value: 'all' },
          { label: 'Dapp', value: 'DApp' },
          { label: 'Panel', value: 'SendViaPanel' },
          { label: 'Manual', value: 'Manually' }
        ]}
      />
      <SelectFilter
        name="type"
        value={filters.type}
        onChange={handleFilters}
        options={[
          { label: 'All Types', value: 'all' },
          { label: 'Sale', value: 'Sale' },
          { label: 'Upgrade', value: 'Upgrade' }
        ]}
      />
      <ClearFiltersButton onClick={onClearFilters}>Clear Filters</ClearFiltersButton>
    </Container>
  )
}

interface SelectProps {
  name: string
  value: string
  onChange: any
  options: Array<{ label: string; value: string }>
}

const SelectFilter = ({ name, value, onChange, options }: SelectProps) => {
  const getSelectedOption = () => {
    const item = options.find((itemSelect) => itemSelect.value === value)
    if (item) return item.label

    return '-'
  }

  const handleSelect = (event: SelectChangeEvent) => {
    const selectedValue: any = event.target.value
    onChange((state: any) => ({ ...state, [name]: selectedValue }))
  }

  return (
    <Select
      id="item_type"
      labelId="item_type_label"
      value={value || ''}
      size="medium"
      onChange={handleSelect}
      displayEmpty
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.$disabledBg2}`,
          borderRadius: 0
        },
        '.MuiOutlinedInput-input': {
          paddingTop: '14px',
          paddingBottom: '14px'
        },
        '.MuiFormLabel-root div': {
          color: colors.$primaryLight + ' !important'
        }
      }}
      MenuProps={{
        sx: {
          '.MuiPaper-root': {
            borderRadius: 0
          }
        }
      }}
      renderValue={() => (
        <InputLabel
          disableAnimation
          shrink={false}
          focused={value !== undefined}
          id="item_type_label"
          sx={{ cursor: 'pointer', fontSize: 16, fontWeight: 500 }}
        >
          {getSelectedOption()}
        </InputLabel>
      )}
    >
      {options.map((item) => (
        <MenuItemWrapper key={item.label} value={item.value}>
          {item.label}
          <RadioButton checked={item.value === value} />
        </MenuItemWrapper>
      ))}
    </Select>
  )
}

const Container = styled.div`
  display: flex;
  gap: 8px;
  margin: 24px 0px;
  .MuiInputBase-root {
    height: 50px !important;
  }

  > :first-child,
  > :nth-child(2) {
    flex: 1;
    min-width: 175px;
  }

  > :nth-child(3),
  > :nth-child(4) {
    width: 155px;
    min-width: 155px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      width: 100%;
    }
  }

  > :nth-child(5),
  > :nth-child(6),
  > :nth-child(7) {
    width: 130px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      width: 100%;
    }
  }

  /* @media (max-width: ${MEDIA_WIDTHS.upToLarge}px) { */
  flex-wrap: wrap;
  /* } */
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    flex-direction: column;
  }
`

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  &:hover {
    color: ${colors.$black};
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
