import { Chip, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AvailabilityInfoProps } from 'src/types/common.types'
import { colors } from 'src/utils/colorVariables'

interface Props {
  selectedNftIdx?: string
  sales: number | undefined
  maxAmount: string | undefined
  availableAmount?: string | number
  showAvailable?: boolean
}

const AvailableNftChip = ({
  maxAmount,
  selectedNftIdx,
  sales,
  showAvailable,
  availableAmount
}: Props) => {
  const [info, setInfo] = useState<AvailabilityInfoProps>()

  useEffect(() => {
    if (maxAmount) {
      setInfo({ sales, maxAmount, availableAmount })
    }
  }, [sales, maxAmount, selectedNftIdx, availableAmount])

  const label = showAvailable
    ? `Available: ${availableAmount}`
    : `${info?.sales} (Max ${info?.maxAmount}/person)`

  return (
    <ChipWrapper
      sx={
        Number(info?.balance) >= Number(info?.maxAmount)
          ? { backgroundColor: colors.$grayLight }
          : { backgroundColor: colors.$successOrange }
      }
      label={label}
    />
  )
}

export default AvailableNftChip

const ChipWrapper = styled(Chip)`
  position: absolute;
  right: 40px;
  top: calc(50% - 10px);
  height: 20px;
  color: ${colors.$white};

  @media (max-width: 599px) {
    position: static;
  }
`
