import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useStore } from 'effector-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useAvailableNft } from 'src/hooks/useAvailableNft'
import useMetaMask from 'src/hooks/useMetaMask'
import {
  $nftCollection,
  $userNftSales,
  setNftAvailabilityInfo,
  updateSelectedNftTypeIdx
} from 'src/state/effector/store'
import { AvailabilityInfoProps, IResources } from 'src/types/common.types'
import { isMobile } from 'src/utils/isMobile'
import AvailableNftChip from './availableNftChip'
interface Props {
  selectedNftTypeIndex: number
  selectedNftTypeIndexHandler: (i: number) => void
  handleAvailabilityInfo?: (info: AvailabilityInfoProps) => void
  showAvailable?: IResources
  isAdmin?: boolean
}

const getNFTKeyByIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'foundingOwners'
    case 1:
      return 'secondOwners'
    case 2:
      return 'extraOwners'
    default:
      console.error('Wrong NFT key')
      break
  }
}

const NftKindSelect = ({
  showAvailable,
  selectedNftTypeIndexHandler,
  selectedNftTypeIndex,
  isAdmin
}: Props) => {
  const { chainId, account } = useMetaMask()
  const { availabilityInfo, getNftAvailability } = useAvailableNft(isAdmin)

  const nftCollection = useStore($nftCollection)
  const userNftSales = useStore($userNftSales)
  const [listOfNft, setListOfNft] = useState<AvailabilityInfoProps[]>()
  const mobile = isMobile()

  // Convert number to string
  const selectedNftIdx: string = useMemo(() => String(selectedNftTypeIndex), [selectedNftTypeIndex])

  const handleChangeNftKind = (event: SelectChangeEvent) => {
    selectedNftTypeIndexHandler(Number(event.target.value))
    updateSelectedNftTypeIdx(Number(event.target.value))
    // setSelectedNftIdx(event.target.value)
  }

  useEffect(() => {
    if (availabilityInfo && selectedNftIdx) {
      setListOfNft(availabilityInfo as AvailabilityInfoProps[])
      setNftAvailabilityInfo((availabilityInfo as AvailabilityInfoProps[])[Number(selectedNftIdx)])

      // handleAvailabilityInfo((availabilityInfo as AvailabilityInfoProps[])[Number(selectedNftIdx)]);
    }
  }, [availabilityInfo, selectedNftIdx])
  //
  // useEffect(() => {
  //   selectedNftTypeIndexHandler(Number(selectedNftIdx))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedNftIdx])

  useEffect(() => {
    getNftAvailability()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, nftCollection, userNftSales])

  const getAvailableResource = (idx: number) => {
    const nftKey = getNFTKeyByIndex(Number(idx)) || 0
    return showAvailable && nftKey ? showAvailable[nftKey]?.balance || 0 : 0
  }

  return (
    <Box>
      {mobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <AvailableNftChip
            selectedNftIdx={selectedNftIdx}
            sales={listOfNft && (listOfNft as AvailabilityInfoProps[])[+selectedNftIdx].sales}
            maxAmount={
              listOfNft && (listOfNft as AvailabilityInfoProps[])[+selectedNftIdx].maxAmount
            }
            availableAmount={listOfNft && getAvailableResource(+selectedNftIdx)}
            showAvailable={Boolean(showAvailable)}
          />
        </div>
      )}
      <FormControl fullWidth>
        <Select
          onChange={handleChangeNftKind}
          value={listOfNft && listOfNft.length ? selectedNftIdx : ''}
        >
          {listOfNft &&
            listOfNft.length &&
            listOfNft.map(({ sales, maxAmount, nftName }: AvailabilityInfoProps, idx) => (
              <MenuItem key={String(idx)} value={String(idx)}>
                {nftName}
                {!mobile && (
                  <AvailableNftChip
                    sales={sales}
                    maxAmount={maxAmount}
                    showAvailable={Boolean(showAvailable)}
                    availableAmount={getAvailableResource(idx)}
                    selectedNftIdx={selectedNftIdx}
                  />
                )}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default NftKindSelect
