import { networks } from 'src/constants/currency'

export const getNftAddressByIndxAndChainId = (indx: number, chainId: number) => {
  const now = new Date()

  const addresses = networks[
    `${chainId}` as keyof typeof networks
  ].networkAddresses.NftTypes.filter(
    (x) => !x.discontinueDate || !x.startDate || (x.discontinueDate > now && x.startDate < now)
  )

  return addresses[indx].SixthSocietyNFT
}

export const getNftName = (chainId: number, address?: string) => {
  const nftAddress =
    address ??
    networks[`${chainId}` as keyof typeof networks].networkAddresses.NftTypes[0].SixthSocietyNFT

  const type = networks[`${chainId}` as keyof typeof networks].networkAddresses.NftTypes.find(
    (x) => x.SixthSocietyNFT.toLowerCase() === nftAddress.toLowerCase()
  )!

  const match = type.fullname.match(/([0-9]{4})/)

  return `SXT${match?.length ? match[0] : ''}`.trim()
}
