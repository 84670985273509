import { ENftTypes } from '../../../../constants/nftTypes'
import { IValues } from './types'
import { ENetworkTypes, IResources } from '../../../../types/common.types'

export const getNFTTypeByIndex = (index: number) => {
  switch (index) {
    case 0:
      return ENftTypes.Founding
    case 1:
    case 2:
      return ENftTypes.Second
    default:
      return ENftTypes.Founding
  }
}

export const serializedValues = (values: IValues, networkType: ENetworkTypes) => {
  return {
    ...values,
    nftType: getNFTTypeByIndex(values.nftType),
    networkType
  }
}

export const getNFTKeyByIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'foundingOwners'
    case 1:
      return 'secondOwners'
    case 2:
      return 'extraOwners'
    default:
      console.error('Wrong NFT key')
      break
  }
}

export const getAvailableResource = (idx: number, resources: IResources) => {
  const nftKey = getNFTKeyByIndex(Number(idx)) || 0
  const resource = resources && nftKey ? `${resources[nftKey]?.balance}` : '0'
  return +resource.substring(0, resource.indexOf(' ')) ?? 0
}

export const getNFTTypeTitle = (index: number) => {
  switch (index) {
    case 0:
      return 'Founding member NFT'
    case 1:
      return '2023 NFT'
    default:
      return ''
  }
}

export const getNFTTitleByName = (name: ENftTypes) => {
  switch (name) {
    case ENftTypes.Founding:
      return 'Founding member NFT'
    case ENftTypes.Second:
      return '2023 NFT'
    default:
      return undefined
  }
}

export const getNFTIndexByName = (name: ENftTypes) => {
  switch (name) {
    case ENftTypes.Founding:
      return 0
    case ENftTypes.Second:
      return 1
    default:
      return undefined
  }
}
