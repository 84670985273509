import axios, { AxiosResponse } from 'axios'
import { GetLogsParams } from 'src/state/effector/history/logs.types'
import { setAuth } from 'src/state/effector/store'
import { API_URL } from '../config'
import {
  AddMember,
  AddMemberValidateParams,
  AddTeamMember,
  AddTeamMemberValidateParams,
  EditMember,
  GetUsersParams
} from '../state/effector/users/user.types'
import {
  AdminSendNft,
  BuyNFT,
  IIsAllowBuyNFTs,
  ISellTransactions,
  ISendEmailStatus,
  ISetHiddenUnhiddenUser,
  IUserNftSaleEntry
} from '../types/common.types'
import { modifyModeForQuery } from '../utils/common.utils'
import { metamask, unauthorizedUrls } from './apiUrls'
import { Login, MetamaskChallenge } from './types'
import { PaginationTypes } from '../types/pagination.types'
import { responseRejectInterceptor } from './interceptors'
import { Signature } from 'ethers'

const getHeaders = (): Record<string, any> => {
  const auth = JSON.parse((window as any).localStorage.getItem('auth'))

  const address = (window as any).localStorage.getItem('walletAddr')
  if (auth?.[address]) {
    return { Authorization: `Bearer ${auth?.[address].accessToken}` }
  } else {
    if (auth && Object.keys(auth).length) {
      delete auth?.[address]
      setAuth(auth)
    }
  }

  return {}
}

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const headers = getHeaders()

    const withAuthorization = !unauthorizedUrls.find((url) =>
      config?.url ? config?.url?.includes(url) || url.includes(config?.url) : true
    )

    if (withAuthorization && !headers.Authorization) {
      return Promise.reject(new Error('Mising Authorization'))
    }

    config = {
      ...config,
      headers
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.request.use((config) => {
  const baseURL = API_URL

  // development env
  // const baseURL = 'https://dev.sixthsociety.com:3000/v1/'

  // staging
  // const baseURL = 'https://staging.sixthsociety.com:3000/v1/'

  // prod
  // const baseURL = 'https://api.sixthsociety.com/v1/'

  return { ...config, baseURL }
})

axios.interceptors.response.use((res) => res, responseRejectInterceptor)

const sendMetamaskAddress = (address: string) => {
  return axios.post<MetamaskChallenge>(metamask.challenge, { address })
}

const login = (address: string, hash: string) => {
  return axios.post<Login>(metamask.login, { address, hash })
}

const isBEHealth = () => {
  return axios.get('health')
}

const getLastMaintenanceState = () => {
  return axios.get('getLastMaintenanceState')
}

const switchMaintenanceMode = (enabled: boolean) => {
  const params = {
    enabled
  }
  return axios.post(`dangerous/maintenance?enabled=${enabled}`, params)
}

const checkIfBuddy = (address: string) => {
  return axios.get(`users/isBuddy/${address}`)
}

const setBuddy = (address: string) => {
  return axios.post(`users/buddy/${address}`, modifyModeForQuery({}))
}

const getBuddy = () => {
  //   return

  return axios.get(`users/buddy`)
}

const saveTransaction = (data: object) => {
  return axios.post('token/sellTransaction', modifyModeForQuery({ ...data }))
}

const getTransactions = (params: { chainId: number }) => {
  return axios.get('token/sellTransaction', { params })
}

const getIsAllowBuyNFTs = (
  ethAddress: string,
  chainId: number
): Promise<AxiosResponse<IIsAllowBuyNFTs>> => {
  return axios.get(`token/isAllowBuyNft/${ethAddress}?chainId=${chainId}`)
}

const getNftSales = (
  ethAddress: string,
  chainId: number
): Promise<AxiosResponse<IUserNftSaleEntry[]>> => {
  return axios.get(`token/getAllNftSales/${ethAddress}?chainId=${chainId}`)
}

const sendEmail = (email: string): Promise<AxiosResponse<ISendEmailStatus>> => {
  return axios.patch(`user/whitelist`, { email })
}

// auth
const getUser = () => {
  return axios.get(`auth/me`)
}

const getTribeToken = () => {
  return axios.get(`/auth/tribe-token`)
}

// users
const getUserWhitelistStatus = (): Promise<AxiosResponse> => {
  return axios.get(`users/getUserWhiteListStatus`)
}

const getUsers = (params: GetUsersParams) => {
  return axios.get(`users`, { params })
}

const getUserByEthAddress = (ethAddress: string) => {
  return axios.get(`/users/getUserByEthAddress/${ethAddress}`)
}

const getPendingWhitelistUsersCount = (showHiddenUsers = false) => {
  const params = { showHiddenUsers }
  return axios.get(`users/getPendingWhiteListUsers`, { params })
}

const getPendingUsersCount = (showHiddenUsers = false) => {
  const params = { showHiddenUsers }
  return axios.get(`users/getPendingUsers`, { params })
}

const addMemberValidate = (params: AddMemberValidateParams) => {
  return axios.get(`users/addMemberValidate`, { params })
}

const addTeamMemberValidate = (params: AddTeamMemberValidateParams) => {
  return axios.get(`users/addTeamMemberValidate`, { params })
}

const addMember = (params: AddMember) => {
  return axios.post(`/users/add-member`, params)
}

const editMember = (params: EditMember) => {
  return axios.put(`/users/edit-member`, params)
}

const addTeamMember = (params: AddTeamMember) => {
  return axios.post(`/users/add-team-member`, params)
}

const editTeamMember = (params: AddTeamMember) => {
  return axios.put(`/users/edit-team-member`, params)
}

const getBuddyStatusOfUser = (params: string) => {
  return axios.get(`/users/getBuddyStatusOfUser/${params}`)
}

const isUserWhiteListAccepted = (ethAddress: string) => {
  return axios.get(`users/isUserWhiteListAccepted/${ethAddress}`)
}

const getAnyNft = (ethAddress: string, chainId: number) => {
  return axios.get(`/users/getAnyNft/${ethAddress}?chainId=${chainId}`).then((res) => res.data)
}

const getMyCountry = () => {
  return axios.get('/users/myCountry').then((res) => res.data)
}

const setHiddenUnhiddenUser = (data: ISetHiddenUnhiddenUser): Promise<AxiosResponse<any>> => {
  return axios.patch(`user/setHidden`, data)
}

// token
const buyNft = (params: BuyNFT) => {
  return axios.post(`/token/buyNft`, params)
}

interface BuyNFTGasLess {
  chainId: number
  nftType: string
  nftAddress: string
  isEIP2612Compatible: boolean
  path: string
  deadline: number
  amount: number
  permitHash: Signature
}

const buyNftGasless = (params: BuyNFTGasLess) => {
  return axios.post(`/token/buyNft/gasless`, params)
}

const getResources = (chainId: number) => {
  return axios.get(`/resources?chainId=${chainId}`).then((res) => res.data)
}

const getOverview = (chainId: number) => {
  return axios.get(`/token/overview?chainId=${chainId}`).then((res) => res.data)
}

const getSellTransactions = (params: ISellTransactions) => {
  return axios.get('token/sellTransactions', { params })
}

//sendNFT
const adminSendNft = (params: AdminSendNft) => {
  return axios.post(`/token/sendNft`, params)
}

const adminValidateSendNft = (params: AdminSendNft) => {
  return axios.get(`/token/sendNftValidate`, { params })
}
//bookNFT

const adminBookNft = (params: AdminSendNft) => {
  return axios.post(`/bookNft/create`, params)
}

const adminValidateBookNft = (params: AdminSendNft) => {
  return axios.get(`/bookNft/validate`, { params })
}

const getBookedNFTList = (params: PaginationTypes) => {
  return axios.get(`/bookNft/list`, { params })
}
const getBookedNFTByID = (id: number) => {
  return axios.get(`/bookNft/getDataById/${id}`)
}
const sendBookedNFT = (chainId: string, bookNftId: number) => {
  return axios.post(`/bookNft/send`, { chainId, bookNftId })
}
const rejectBookedNFT = (bookNftId: number) => {
  return axios.post(`/bookNft/cancel`, { bookNftId })
}

// history
const getLogs = (params: GetLogsParams) => {
  return axios.get(`history/logs`, { params })
}

export const refreshAuthToken = (refreshToken: string) => {
  return axios.post(`${API_URL}/auth/refresh`, {
    refreshToken
  })
}

interface AvailableIdsProps {
  nftType: string
  amount: number
  networkType: string
  nftAddress: string
}
const getAvailableNftIds = (params: AvailableIdsProps) => {
  return axios.get(`/token/getNftIds`, { params })
}

interface NftUpgradePriceProps {
  id: string
  nftAddress: string
  networkType: string
}
const getNftUpgradePrice = (params: NftUpgradePriceProps) => {
  return axios.get('/token/getNftPrice', { params })
}

interface NftUpgradeSignatureProps {
  nftId: number
  nftAddress: string
  networkType: string
}
const getNftUpgradeSignature = (params: NftUpgradeSignatureProps) => {
  return axios.post('/token/upgradeNft', params)
}

interface UpgradeNftGasless {
  chainId: number
  nftAddress: string
  nftId: number
  isEIP2612Compatible: boolean
  path: string
  deadline: number
  permitHash: Signature
}

const upgradeNftGasless = (params: UpgradeNftGasless) => {
  return axios.post('/token/upgradeNft/gasless', params)
}

interface NftUpgradeRequestProps {
  nftId: number
  nftAddress: string
  network: string
}
const createUpgradeRequest = (params: NftUpgradeRequestProps) => {
  return axios.post('/token/upgradeNft/request', params)
}

const getNftUpgradeRequests = (params: { offset: number; page: number; status: string }) => {
  return axios.get('/token/upgrade-requests', { params })
}

const approveNftUpgrade = ({
  requestId,
  ...params
}: {
  requestId: number
  networkType: string
  id: number
}) => {
  return axios.post(`/token/upgradeNft/approve/${requestId}`, { params })
}

const rejectNftUpgrade = ({
  requestId,
  ...params
}: {
  requestId: number
  networkType: string
  id: number
}) => {
  return axios.post(`/token/upgradeNft/reject/${requestId}`, { params })
}

const getActiveNftRequestUpgrade = (nftId: string) => {
  return axios.get(`/token/upgradeNft/request/${nftId}`)
}

const getTribeTree = () => {
  return axios.get('/users/introducer-tree')
}

const cancelUpgradeRequest = (id: number) => {
  return axios.post(`token/upgradeNft/cancel/${id}`)
}

const getMyRewards = (params: PaginationTypes) => {
  return axios.get('/rewardTransaction/my-rewards', { params })
}

const getAllEventsName = (params: { country: string }) => {
  return axios.get('/rewardTransaction/events', { params })
}

interface GetAllRewards extends PaginationTypes {
  eventName?: string
  walletAddress?: string
  country: string
  executionType?: string
  eventType?: string
}

const getAllRewards = (params: GetAllRewards) => {
  return axios.get('/rewardTransaction/rewards', { params })
}

const getMembersForAirdrop = (params: { search?: string; region: string }) => {
  return axios.get('/airdropEvent/buddies', { params })
}

const createAirdropTemplate = (data: Record<string, any>) => {
  return axios.post('/airdropEvent/template', data)
}

const updateAirdropTemplate = ({
  data,
  templateId
}: {
  templateId: number
  data: Record<string, any>
}) => {
  return axios.put(`/airdropEvent/template/${templateId}`, data)
}

const getAirdropTemplate = (params?: Record<string, any>) => {
  return axios.get('/airdropEvent/templates', { params })
}

const getAirdropMembersCalculations = ({
  templateId,
  ...params
}: {
  templateId: number
  [key: string]: any
}) => {
  return axios.get(`/airdropEvent/members/${templateId}`, { params })
}

const getAirdropSummary = ({
  templateId,
  networkId,
  budget
}: {
  templateId: number
  networkId: number
  budget?: number
}) => {
  return axios.get(`/airdropEvent/summary/${templateId}`, { params: { networkId, budget } })
}

const getTemplate = (templateId: number) => {
  return axios.get(`/airdropEvent/templates/${templateId}`)
}

interface DraftAirdrop {
  template: number
  name: string
  percentage?: string
  budget?: string
  network: string
  specificMembers?: Array<{ ethAddress: string; amount: string }>
}

const createAirdropDraft = (data: DraftAirdrop) => {
  return axios.post('/airdropEvent/draft-event', data)
}

const deleteAirdropDraft = (id: number) => {
  return axios.delete(`/airdropEvent/draft-event/${id}`)
}

const executeAirdrop = (draftId: number) => {
  return axios.put(`/airdropEvent/execute/${draftId}`)
}

const getAirdropEvents = (params: any) => {
  return axios.get('/airdropEvent/events', { params })
}

const getAirdropEvent = (id: number) => {
  return axios.get(`/airdropEvent/events/${id}`)
}
const archiveTemplate = (id: number) => {
  return axios.delete(`/airdropEvent/template/${id}`)
}
const unarchiveTemplate = (id: number) => {
  return axios.put(`/airdropEvent/template/restore/${id}`)
}

const getManualSummary = (params: Record<string, any>) => {
  return axios.get('/airdropEvent/summary', { params })
}

const createOneTimeEvent = (data: Record<string, any>) => {
  return axios.post('/airdropEvent/onetime-event', data)
}

interface VerifyMemberNft {
  network: string
  frequency: string
  isVotingRequired: boolean
  ethAddress: string
}

const verifyMemberNft = ({ ethAddress, ...params }: VerifyMemberNft) => {
  return axios.get(`/users/nftBalanceStatus/${ethAddress}`, { params })
}

const retryAirdropEvent = (id: number) => {
  return axios.put(`/airdropEvent/retry/${id}`)
}

const generateCsv = (id: number) => {
  return axios(`airdropEvent/csv/${id}`, {
    method: 'GET',
    responseType: 'blob'
  })
}

interface GetBudgetPayment {
  chainId: number
  percentage: number
}

const getBudgetPayment = ({ chainId, percentage }: GetBudgetPayment) => {
  return axios.get(`/airdropEvent/budget/${chainId}`, { params: { percentage } })
}

const getLastBudgetPayment = ({ chainId, percentage }: GetBudgetPayment) => {
  return axios.get(`/airdropEvent/last-quarter-budget/${chainId}`, { params: { percentage } })
}

const saveEventNote = (eventId: number, note: string) => {
  return axios.post(`/airdropEvent/note/${eventId}`, { note })
}

const getHiddenNftList = () => {
  return axios.get('/token/hidden/nft-list')
}

interface HideNft {
  nftAddress: string
  tokenId: string | number
}

const hideNft = (data: HideNft) => {
  return axios.post(`/token/hidden/nft-post`, data)
}

const unhideNft = ({ nftAddress, tokenId }: HideNft) => {
  return axios.delete(`/token/hidden/nft-delete/${nftAddress}/${tokenId}`)
}

const apiService = {
  sendMetamaskAddress,
  login,
  getUser,
  checkIfBuddy,
  setBuddy,
  getBuddy,
  getUsers,
  getUserByEthAddress,
  addMember,
  editMember,
  addTeamMember,
  addTeamMemberValidate,
  editTeamMember,
  isUserWhiteListAccepted,
  getBuddyStatusOfUser,
  getPendingWhitelistUsersCount,
  getPendingUsersCount,
  addMemberValidate,
  setHiddenUnhiddenUser,
  saveTransaction,
  getTransactions,
  getIsAllowBuyNFTs,
  buyNft,
  getNftSales,
  sendEmail,
  getUserWhitelistStatus,
  getTribeToken,
  getAnyNft,
  getMyCountry,
  adminSendNft,
  adminValidateSendNft,
  adminBookNft,
  getBookedNFTList,
  getBookedNFTByID,
  sendBookedNFT,
  rejectBookedNFT,
  adminValidateBookNft,
  getResources,
  getOverview,
  getLogs,
  getSellTransactions,
  refreshAuthToken,
  isBEHealth,
  getLastMaintenanceState,
  switchMaintenanceMode,
  getAvailableNftIds,
  getNftUpgradePrice,
  createUpgradeRequest,
  getNftUpgradeSignature,
  getNftUpgradeRequests,
  approveNftUpgrade,
  rejectNftUpgrade,
  getActiveNftRequestUpgrade,
  getTribeTree,
  cancelUpgradeRequest,
  getMyRewards,
  getAllRewards,
  getAllEventsName,
  createAirdropTemplate,
  getAirdropTemplate,
  getMembersForAirdrop,
  getAirdropMembersCalculations,
  getAirdropSummary,
  getTemplate,
  updateAirdropTemplate,
  createAirdropDraft,
  deleteAirdropDraft,
  executeAirdrop,
  getAirdropEvents,
  getAirdropEvent,
  archiveTemplate,
  unarchiveTemplate,
  createOneTimeEvent,
  getManualSummary,
  verifyMemberNft,
  retryAirdropEvent,
  generateCsv,
  buyNftGasless,
  upgradeNftGasless,
  getBudgetPayment,
  getLastBudgetPayment,
  saveEventNote,
  getHiddenNftList,
  hideNft,
  unhideNft
}

export default apiService
