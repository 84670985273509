import { OwnedNft } from 'alchemy-sdk'
import { createEffect, createEvent, createStore } from 'effector'

import {
  AvailabilityInfoProps,
  CurrencyInfoProps,
  IIsAllowBuyNFTs,
  IUserNftSaleEntry,
  PriceInfoProps,
  WhiteListStatuses
} from 'src/types/common.types'
import { Domain } from 'src/types'

import { User, WhitelistTypes } from './users/user.types'

export const checkIfCanBeBuddyFn = createEffect((address: string) => {
  return address
})

const localStorageAuth = window.localStorage.getItem('auth')

export const setAuth = createEffect((auth: any) => auth)

export const $auth = createStore(localStorageAuth ? JSON.parse(localStorageAuth) : {}).on(
  setAuth,
  (state, auth) => {
    window.localStorage.setItem('auth', JSON.stringify(auth))
    return auth
  }
)

export const $referralBuddyAddress = createStore('').on(checkIfCanBeBuddyFn, (state, addr) => addr)

export const saveConnectingHash = createEffect((hash: string) => hash)

export const $dataHash = createStore<string>('').on(saveConnectingHash, (state, hash) => {
  return hash
})

export const setIsMaintenanceModeActive = createEvent<boolean>()
export const $isMaintenanceModeActive = createStore<boolean>(false).on(
  setIsMaintenanceModeActive,
  (_, value) => value
)

export const setIsNotAllowedServer = createEvent<boolean>()
export const $isNotAllowedServer = createStore<boolean>(false).on(
  setIsNotAllowedServer,
  (_, value) => value
)

export const setNftAvailabilityInfo = createEvent<AvailabilityInfoProps>()
export const saveWhitelistUserStatus = createEvent<WhiteListStatuses>()

export const saveBuddyInStore = createEvent<string>()
export const saveBuddyWhiteListStatus = createEvent<WhitelistTypes>()
export const saveNftTransferBooked = createEvent<boolean>()
export const saveCurrencyInfo = createEvent<CurrencyInfoProps>()
export const saveCurrentPriceInfo = createEvent<PriceInfoProps>()

export const saveIsLoadingBuyNFT = createEvent<{ [key: string]: boolean }>()
export const savePotentialBuddyAddress = createEvent<string>()

//Available Types: Null | Number
export const saveNftCollection = createEvent<any>()
export const updateTokenBalanceTrigger = createEvent<object>()
export const saveIsBuddyLoading = createEvent<boolean>()
export const checkIfRedirected = createEvent<boolean>()
export const saveBuddyStatus = createEvent<boolean>()
//Available Types: Null | Boolean
export const saveIsBuddy = createEvent<any>()

export const updateNftList = createEvent<INftListPayload>()
export const setOurNftList = createEvent<OwnedNft[]>()
export const setOtherNftList = createEvent<OwnedNft[]>()
export const saveIsCollectionLoading = createEvent<boolean>()
export const deleteItemFromNftListByTokenId = createEvent<number>()
export const clearNftList = createEvent()
export const updateSelectedNftTypeIdx = createEvent<number>()
export const setPageKey = createEvent<string | null>()

export const updateSelectedTokenIndex = createEffect((idx: string) => idx)

export const $isBuddyLoading = createStore<boolean>(true)
  .on(saveBuddyInStore, () => false)
  .on(saveIsBuddyLoading, (state, isLoading) => isLoading)

export const setIsUserAllowBuyNFTs = createEvent<IIsAllowBuyNFTs>()

export const setUserNftSales = createEvent<IUserNftSaleEntry[]>()

export const setIsShowNavigation = createEvent<boolean>()

export const setSelectedNftAddress = createEvent<string>()

export const triggerSignatureEffect = createEffect((trigger: boolean) => trigger)

export const setTribeToken = createEvent<string>()

export const $availableNft = createStore<AvailabilityInfoProps>({} as AvailabilityInfoProps).on(
  setNftAvailabilityInfo,
  (state, nft) => nft
)

export const $whitelistUserStatus = createStore<WhiteListStatuses>('UNKNOWN').on(
  saveWhitelistUserStatus,
  (_, status) => status
)

export const $isCurrentAddrBuddy = createStore(false).on(
  saveBuddyStatus,
  (state, isBuddy) => isBuddy
)
export const $potentialBuddyAddress = createStore('').on(
  savePotentialBuddyAddress,
  (state, buddyAddr) => buddyAddr
)

export const $isRedirected = createStore(false).on(
  checkIfRedirected,
  (state, isRedirected) => isRedirected
)

export const $registeredBuddy = createStore('').on(
  saveBuddyInStore,
  (state, buddyAddr) => buddyAddr
)

export const $nftCollection = createStore(null).on(saveNftCollection, (state, nfts) => nfts)

export const $currencyInfo = createStore<CurrencyInfoProps>({} as CurrencyInfoProps).on(
  saveCurrencyInfo,
  (state, info) => info
)

export const $currentPriceInfo = createStore<PriceInfoProps>({} as PriceInfoProps).on(
  saveCurrentPriceInfo,
  (state, info) => info
)

export const $isLoadingBuyNFTData = createStore<{ [key: string]: boolean }>({}).on(
  saveIsLoadingBuyNFT,
  (state, data) => ({ ...state, ...data })
)

export const $isLoadingBuyNFT = createStore<boolean>(true).on($isLoadingBuyNFTData, (state, data) =>
  Object.values(data).some((value) => value)
)

export const $updateBalanceCounter = createStore({ count: 0 }).on(
  updateTokenBalanceTrigger,
  (state, updateBalanceCounter: any) => updateBalanceCounter //console.log(updateBalanceCounter)
)

export const $selectedTokenIndex = createStore('0').on(updateSelectedTokenIndex, (state, idx) => {
  // console.error('stored idx ', idx)
  return idx
})

export const $selectedNftTypeIndex = createStore<number>(0).on(
  updateSelectedNftTypeIdx,
  (state, idx) => idx
)

export const $triggerSignature = createStore(false).on(triggerSignatureEffect, (state, trigger) => {
  // console.error('stored idx ', idx)
  return trigger
})

export const $isBuddy = createStore(null).on(saveIsBuddy, (state, trigger) => {
  return trigger
})

export const saveBuddy = createEvent<User | null>()

export const $buddy = createStore<User | null>(null).on(saveBuddy, (state, data) => data)

interface INftListPayload {
  append: boolean
  nfts: OwnedNft[]
}

export const $nftList = createStore<OwnedNft[]>([])
  .on(updateNftList, (state, payload: INftListPayload) => {
    if (payload.append) {
      return [...state, ...payload.nfts]
    }
    return payload.nfts
  })
  .on(deleteItemFromNftListByTokenId, (state, tokenId) =>
    state.filter((token) => !(Number(token.tokenId) === tokenId && Number(token.balance === 1)))
  )
  .on(clearNftList, () => {
    return []
  })

export const $ourNftList = createStore<OwnedNft[]>([]).on(setOurNftList, (_, payload) => payload)

export const $otherNftList = createStore<OwnedNft[]>([]).on(
  setOtherNftList,
  (_, payload) => payload
)

export const $activeNfts = createStore(0).on($ourNftList, (_, payload: OwnedNft[]) => {
  const activeNfts = payload.filter((nft) => {
    if (nft.rawMetadata?.expiration === 'never') return true

    return nft.rawMetadata?.expiration ? +nft.rawMetadata?.expiration > new Date().getTime() : true
  })

  return activeNfts.length
})

export const $isCollectionLoading = createStore<boolean>(false).on(
  saveIsCollectionLoading,
  (_, payload: boolean) => payload
)

export const $pageKey = createStore<string | null>(null).on(setPageKey, (state, payload) => payload)

export const $isUserAllowBuyNFTs = createStore<IIsAllowBuyNFTs | null>(null).on(
  setIsUserAllowBuyNFTs,
  (_, payload) => payload
)

export const $userNftSales = createStore<IUserNftSaleEntry[] | null>(null).on(
  setUserNftSales,
  (_, payload) => payload
)

export const $isShowNavigation = createStore<boolean>(false).on(
  setIsShowNavigation,
  (_, payload) => payload
)

export const $tribeToken = createStore<string>('').on(setTribeToken, (_, payload) => payload)

export const $selectedNftAddress = createStore<string>('').on(
  setSelectedNftAddress,
  (_, payload) => payload
)

export const $buddyWhiteListStatus = createStore<WhiteListStatuses | null>(null).on(
  saveBuddyWhiteListStatus,
  (_, payload) => payload
)
export const $isNftTransferBooked = createStore<boolean>(false).on(
  saveNftTransferBooked,
  (_, payload) => payload
)
